// Libraries
import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

// Components
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../../components/faq/faq-accordion";
import HeroChevron from "../../../components/hero/hero-chevron";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import MortgageCalculator from "../../../components/calculators/mortgage-calculator/calculator";
import HelpSection from "../../../components/home-loans/help-section";
import ReturningBorrowerLink from "../../../components/home-loans/returning-borrower-link";
import CustomLargeCards from "../../../components/card/custom-large-cards";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import NewUIWafdBankBenefits from "../../../components/wafd-bank-benefits/new-ui-wafd-bank-benefits";

// Helpers
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";
import PreApprovalWorkflow from "../../../components/home-loans/pre-approval-workflow";

const HomeMortgage = () => {
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/home-mortgage/hero-home-loans-12282022-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/home-mortgage/hero-home-loans-12282022-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/home-mortgage/hero-home-loans-12282022-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/home-mortgage/hero-home-loans-12282022-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/home-mortgage/hero-home-loans-12282022-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/home-mortgage/hero-home-loans-12282022-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/home-mortgage/hero-home-loans-12282022-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      largeCard1: file(relativePath: { eq: "cards/personalization/thumbnail-closing-costs-03152023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2: file(relativePath: { eq: "cards/thumbnail-cross-collateral-12272022.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const heroChevronData = {
    id: "home-mortgage-hero",
    ...getHeroImgVariables(pageData),
    altText: "Happy couple moving into a new home.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Mortgages Made Simple"
          }
        },
        {
          id: 2,
          button: {
            id: "home-mortgage-hero-cta-1",
            text: "See Today's Rates",
            url: "/personal-banking/home-loans/mortgage-rates",
            class: "btn-white",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          button: {
            id: "home-mortgage-hero-cta-2",
            text: "Use Loan Calculator",
            url: "#mortgage-calculator-section",
            class: "btn-light",
            containerClass: "mb-3",
            icon: {
              position: "right",
              lib: "fas",
              name: "arrow-down",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Home Mortgage"
    }
  ];

  const title = "Home Mortgage Loan - Rates and Calculator";
  const description =
    "Ready to buy your next home? Learn about our home mortgage loans at WaFd Bank. Check out our latest mortgage rates and calculator today for your next home.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/home-mortgage"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-home-loans-12282022.jpg"
      }
    ]
  };

  const AVG_PRICE = 350000;
  const calculatorData = {
    title: "Home Loan",
    loanType: "Home Loan",
    loanTerm: "30 Year Fixed",
    interestRate: 0,
    estimatedPayment: 1181,
    purchasePrice: AVG_PRICE,
    downPaymentPercent: 20,
    downPaymentAmount: AVG_PRICE * 0.2,
    loanAmount: AVG_PRICE - AVG_PRICE * 0.2,
    disclaimersSectionClass: "pt-0"
  };
  const helpSectionData = {
    sectionClass: "bg-light",
    text: "If you're a first time home buyer and have done all your research but want help, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have."
  };

  const portfolioVideoData = {
    vimeoId: "393775134",
    minHeight: "250px",
    title: "The Advantage of a Portfolio Lender Video",
    class: "m-auto iframe w-100 border-radius-12"
  };

  const faqAccordionData = {
    id: "home-loans-faq-accordion",
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    seeMoreFaqsId: "home-loans-see-more-faqs-link",
    faqCategoryNames: ["Home Loans"],
    showContactBar: false
  };

  const largeCardsImgData = {
    largeCard1: pageData.largeCard1,
    altTextCard1: "Young couple moving in new house, holding keys.",
    largeCard2: pageData.largeCard2,
    altTextCard2: "Family moving day with dad holding a cardboard moving box and mom holding baby."
  };

  const largeCardsData = [
    {
      title: "Save Up to $600 on Closing Costs With a Checking Account From WaFd Bank*",
      disclaimer:
        "*To qualify for the maximum $600 discount, before closing documents are prepared you must (1) have or open the WaFd checking account, (2) register for EZ-Pay from the new checking account, and (3) sign up for direct deposit of at least one form of a monthly recurring source of income.",
      mainButton: {
        url: "/personal-banking/home-loans/closing-cost-checking-account-offer",
        title: "Find Out How"
      }
    },
    {
      title: "Buy Your New Home Now, Sell Your Current Home Later",
      subtitle:
        "Need the equity in your current house to buy your new home? With our cross-collateral home loan, you won't need to make your offer to buy a new home contingent on selling your existing one.",
      mainButton: {
        url: "/personal-banking/home-loans/easy-move-cross-collateral",
        title: "Find Out How"
      }
    }
  ];

  const cardsData = useCardBlogsData([
    "/blog/home-ownership/12-steps-buying-house",
    "/blog/home-ownership/what-do-mortgage-lenders-look-for",
    "/blog/home-ownership/best-way-to-buy-a-house"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });

  const linkCardsData = {
    sectionClass: "bg-white py-0",
    cards: featuredCardsData.map((card) => ({ ...card, titleClass: "text-green-60" }))
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Home Mortgage Loans</h1>
        <div className="row">
          <div className="col-12 col-md-6">
            <h5 className="font-weight-normal">
              WaFd Bank has been helping generations of families own their homes for over 105 years. Whether you're
              buying, building, or{" "}
              <Link to="/personal-banking/home-loans/mortgage-refinance" id="hm-mortgage-refinance-link">
                refinancing
              </Link>
              , our friendly and knowledgeable loan officers are here for you every step of the way. You can apply
              online, securely upload your documents, and receive updates in our secure online portal. As an added
              bonus, if interest rates go down in the future we offer low-cost modification and refinancing options to
              existing mortgage clients.
            </h5>
          </div>
          <div className="col-12 col-md-6">
            <h5 className="font-weight-normal">
              Ask us about special programs, including asset-based loans, lower down payment options, custom{" "}
              <Link to="/personal-banking/home-loans/construction-loans" id="hm-construction-loans-link">
                construction
              </Link>{" "}
              and loans for manufactured homes, large acreage and unusual properties.
            </h5>
            <ReturningBorrowerLink loanType="Home Loan" />
          </div>
        </div>
      </section>
      <MortgageCalculator {...calculatorData} />
      <PreApprovalWorkflow />
      <CustomLargeCards noBackground imgData={largeCardsImgData} cardsData={largeCardsData} />
      <NewUIWafdBankBenefits />
      <FaqAccordion {...faqAccordionData} />
      <StretchedLinkCards {...linkCardsData} />
      <HelpSection {...helpSectionData} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default HomeMortgage;
